import * as React from 'react';
import { type SVGProps } from 'react';

const AppleAppButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 198 66"
    fill="none"
    data-component="AppleAppButtonIcon"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_890_9231)">
      <mask
        id="mask0_890_9231"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={198}
        height={66}
      >
        <path d="M197.486 0H0V65.8286H197.486V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_890_9231)">
        <path
          d="M181.76 0.000213943H15.7354C15.1303 0.000213943 14.5323 0.000213943 13.9288 0.00350538C13.4235 0.00679681 12.9223 0.0163585 12.4122 0.024406C11.304 0.03741 10.1984 0.13463 9.10507 0.31522C8.01324 0.499742 6.95563 0.847618 5.96798 1.34708C4.98154 1.85077 4.08019 2.50527 3.29665 3.28682C2.509 4.06618 1.85244 4.967 1.3522 5.95468C0.850583 6.94038 0.502759 7.99664 0.320743 9.08699C0.136996 10.176 0.0381201 11.2775 0.0250025 12.3817C0.00968747 12.8863 0.00807013 13.3926 0 13.8972V51.9366C0.00807013 52.4476 0.00968747 52.9427 0.0250025 53.4538C0.0381241 54.558 0.137 55.6595 0.320743 56.7484C0.502256 57.8394 0.850101 58.8962 1.3522 59.8823C1.85221 60.8668 2.50888 61.764 3.29665 62.539C4.07723 63.324 4.97915 63.979 5.96798 64.4788C6.95561 64.9796 8.01312 65.3296 9.10507 65.5169C10.1986 65.696 11.3041 65.7933 12.4122 65.808C12.9223 65.8191 13.4235 65.8255 13.9288 65.8255C14.5323 65.8288 15.1303 65.8288 15.7354 65.8288H181.76C182.352 65.8288 182.955 65.8288 183.548 65.8255C184.051 65.8255 184.567 65.8191 185.07 65.808C186.176 65.7941 187.28 65.6969 188.37 65.5169C189.466 65.3282 190.527 64.9784 191.52 64.4788C192.507 63.9787 193.408 63.3237 194.189 62.539C194.975 61.761 195.632 60.8644 196.139 59.8823C196.637 58.8955 196.981 57.8387 197.161 56.7484C197.344 55.6594 197.446 54.5581 197.467 53.4538C197.473 52.9427 197.473 52.4476 197.473 51.9366C197.486 51.3388 197.486 50.7442 197.486 50.1366V15.694C197.486 15.0913 197.486 14.4935 197.473 13.8972C197.473 13.3926 197.473 12.8863 197.467 12.3816C197.446 11.2773 197.344 10.1761 197.161 9.08692C196.981 7.9972 196.637 6.94102 196.139 5.95461C195.12 3.97494 193.504 2.36351 191.52 1.34694C190.527 0.848691 189.466 0.500906 188.37 0.315072C187.28 0.133686 186.176 0.0364325 185.07 0.0241756C184.567 0.0161445 184.051 0.00650058 183.548 0.00329142C182.955 0 182.352 0.000213943 181.76 0.000213943Z"
          fill="#A6A6A6"
        />
        <path
          d="M13.9369 64.3888C13.4341 64.3888 12.9434 64.3824 12.4446 64.3711C11.4111 64.3577 10.38 64.268 9.35986 64.1028C8.4086 63.9394 7.48707 63.6355 6.62569 63.201C5.7722 62.7703 4.99375 62.2054 4.32016 61.528C3.63684 60.8586 3.06793 60.0823 2.63598 59.2298C2.19929 58.3717 1.89707 57.4519 1.73986 56.5024C1.57008 55.4824 1.47822 54.4508 1.46507 53.4167C1.45462 53.0697 1.44092 51.914 1.44092 51.914V13.8972C1.44092 13.8972 1.4555 12.7593 1.46515 12.425C1.47774 11.3926 1.56906 10.3627 1.73834 9.3441C1.89585 8.3921 2.1983 7.46967 2.63523 6.60876C3.06558 5.7568 3.63132 4.97988 4.31055 4.30813C4.98901 3.63 5.76994 3.0622 6.62488 2.62544C7.48428 2.19243 8.40406 1.89067 9.3534 1.73027C10.3769 1.56334 11.4116 1.47309 12.4486 1.46028L13.9377 1.44019H183.54L185.047 1.46109C186.074 1.47326 187.1 1.56271 188.114 1.72867C189.074 1.89107 190.002 2.19494 190.872 2.63027C192.584 3.51047 193.978 4.90303 194.857 6.61278C195.287 7.46774 195.585 8.38265 195.74 9.32644C195.912 10.3534 196.008 11.3914 196.027 12.4322C196.032 12.8983 196.032 13.3989 196.032 13.8972C196.045 14.5144 196.045 15.1017 196.045 15.6939V50.1365C196.045 50.7345 196.045 51.3179 196.032 51.9061C196.032 52.4412 196.032 52.9315 196.025 53.4361C196.006 54.4583 195.911 55.4778 195.744 56.4864C195.589 57.4427 195.289 58.3696 194.853 59.2347C194.418 60.078 193.852 60.8477 193.177 61.5151C192.502 62.1962 191.722 62.7644 190.867 63.1979C190.001 63.6356 189.072 63.9407 188.114 64.1028C187.095 64.2688 186.063 64.3587 185.029 64.3711C184.546 64.3824 184.04 64.3888 183.548 64.3888L181.76 64.392L13.9369 64.3888Z"
          fill="#1B1C1E"
        />
        <path
          d="M40.877 33.4092C40.8948 32.0355 41.2607 30.6888 41.9407 29.494C42.6206 28.2994 43.5925 27.2955 44.7661 26.576C44.0206 25.5143 43.0371 24.6405 41.8938 24.0242C40.7503 23.4077 39.4785 23.0657 38.1794 23.0251C35.4081 22.7351 32.7214 24.6788 31.3092 24.6788C29.8696 24.6788 27.6954 23.0539 25.3539 23.1019C23.8395 23.1507 22.3635 23.5899 21.0699 24.3767C19.7762 25.1634 18.7089 26.2709 17.9722 27.5913C14.7805 33.1018 17.1611 41.2005 20.2186 45.6548C21.7483 47.8359 23.5361 50.2722 25.8753 50.1858C28.1645 50.0912 29.0193 48.7304 31.7825 48.7304C34.52 48.7304 35.3221 50.1859 37.7088 50.131C40.1652 50.0912 41.7129 47.9401 43.1888 45.7383C44.288 44.1842 45.1336 42.4665 45.6948 40.6489C44.2677 40.047 43.0499 39.0396 42.1931 37.7522C41.3364 36.4647 40.8788 34.9544 40.877 33.4092Z"
          fill="white"
        />
        <path
          d="M36.3687 20.0957C37.7079 18.4924 38.3677 16.4317 38.208 14.3511C36.1619 14.5654 34.2718 15.5407 32.9144 17.0823C32.2507 17.8355 31.7425 18.7118 31.4186 19.661C31.0948 20.6101 30.9616 21.6137 31.0269 22.6142C32.0504 22.6248 33.0629 22.4036 33.9881 21.9673C34.9134 21.5311 35.7273 20.8911 36.3687 20.0957Z"
          fill="white"
        />
        <path
          d="M69.8128 44.6641H62.0011L60.1251 50.1879H56.8164L64.2155 29.7515H67.6532L75.0523 50.1879H71.6871L69.8128 44.6641ZM62.8101 42.1153H69.0022L65.9497 33.1506H65.8643L62.8101 42.1153Z"
          fill="white"
        />
        <path
          d="M91.0322 42.7388C91.0322 47.369 88.547 50.3437 84.7967 50.3437C83.8468 50.3934 82.9018 50.1752 82.0705 49.7141C81.239 49.253 80.5546 48.5679 80.0955 47.7369H80.0246V55.117H76.9575V35.2881H79.9263V37.7662H79.9828C80.4629 36.9394 81.1589 36.2578 81.9967 35.7938C82.8344 35.33 83.7826 35.1014 84.7404 35.1321C88.5325 35.1321 91.0322 38.1214 91.0322 42.7388ZM87.8799 42.7388C87.8799 39.7222 86.3166 37.7389 83.9312 37.7389C81.5879 37.7389 80.0117 39.764 80.0117 42.7388C80.0117 45.741 81.5879 47.7515 83.9312 47.7515C86.3166 47.7515 87.8799 45.7828 87.8799 42.7388Z"
          fill="white"
        />
        <path
          d="M107.477 42.7388C107.477 47.369 104.992 50.3437 101.242 50.3437C100.292 50.3934 99.3471 50.1751 98.5156 49.7141C97.6843 49.253 96.9998 48.5679 96.5408 47.7369H96.4698V55.117H93.4028V35.2881H96.3714V37.7662H96.4279C96.9081 36.9394 97.6041 36.2577 98.4419 35.7938C99.2795 35.33 100.228 35.1014 101.186 35.1321C104.978 35.1321 107.477 38.1214 107.477 42.7388ZM104.325 42.7388C104.325 39.7222 102.762 37.7389 100.377 37.7389C98.0332 37.7389 96.457 39.764 96.457 42.7388C96.457 45.741 98.0332 47.7515 100.377 47.7515C102.762 47.7515 104.325 45.7828 104.325 42.7388Z"
          fill="white"
        />
        <path
          d="M118.346 44.4938C118.573 46.5205 120.548 47.851 123.245 47.851C125.831 47.851 127.69 46.5203 127.69 44.693C127.69 43.1067 126.569 42.157 123.913 41.5061L121.257 40.868C117.494 39.9616 115.746 38.2066 115.746 35.3587C115.746 31.8326 118.828 29.4106 123.204 29.4106C127.534 29.4106 130.503 31.8326 130.603 35.3587H127.507C127.321 33.3192 125.631 32.0882 123.16 32.0882C120.689 32.0882 118.999 33.3338 118.999 35.1465C118.999 36.5914 120.079 37.4416 122.72 38.0923L124.978 38.6453C129.183 39.6368 130.93 41.3212 130.93 44.3104C130.93 48.1338 127.876 50.5285 123.018 50.5285C118.473 50.5285 115.405 48.19 115.207 44.4936L118.346 44.4938Z"
          fill="white"
        />
        <path
          d="M137.549 31.762V35.288H140.39V37.71H137.549V45.924C137.549 47.2001 138.118 47.7948 139.367 47.7948C139.705 47.7889 140.041 47.7653 140.375 47.724V50.1316C139.814 50.2362 139.244 50.2835 138.672 50.273C135.647 50.273 134.467 49.1399 134.467 46.2503V37.71H132.295V35.288H134.467V31.762H137.549Z"
          fill="white"
        />
        <path
          d="M142.036 42.7389C142.036 38.0508 144.805 35.105 149.122 35.105C153.455 35.105 156.21 38.0508 156.21 42.7389C156.21 47.4397 153.468 50.3728 149.122 50.3728C144.778 50.3728 142.036 47.4397 142.036 42.7389ZM153.086 42.7389C153.086 39.5229 151.607 37.625 149.122 37.625C146.637 37.625 145.162 39.5374 145.162 42.7389C145.162 45.9675 146.637 47.8512 149.122 47.8512C151.607 47.8512 153.086 45.9675 153.086 42.7389Z"
          fill="white"
        />
        <path
          d="M158.739 35.2882H161.664V37.8242H161.735C161.933 37.0322 162.399 36.3322 163.053 35.8419C163.707 35.3518 164.511 35.1011 165.33 35.1322C165.682 35.131 166.035 35.1694 166.38 35.2465V38.1071C165.933 37.9712 165.468 37.9087 165.002 37.9223C164.556 37.9043 164.112 37.9826 163.701 38.152C163.288 38.3212 162.918 38.5775 162.613 38.9032C162.31 39.229 162.081 39.6162 161.941 40.0386C161.801 40.461 161.756 40.9085 161.807 41.3503V50.188H158.739V35.2882Z"
          fill="white"
        />
        <path
          d="M180.521 45.8117C180.109 48.5166 177.467 50.3728 174.087 50.3728C169.741 50.3728 167.043 47.4687 167.043 42.8097C167.043 38.136 169.756 35.105 173.958 35.105C178.093 35.105 180.691 37.9367 180.691 42.4545V43.5022H170.138V43.6871C170.09 44.2354 170.159 44.7878 170.341 45.3076C170.522 45.8275 170.813 46.3027 171.193 46.7019C171.573 47.101 172.035 47.4149 172.545 47.6228C173.057 47.8307 173.607 47.9277 174.158 47.9074C174.883 47.9752 175.61 47.8078 176.232 47.4301C176.854 47.0526 177.337 46.485 177.608 45.8117H180.521ZM170.153 41.3647H177.624C177.651 40.8717 177.576 40.3784 177.403 39.9155C177.23 39.4528 176.964 39.0306 176.62 38.6755C176.275 38.3204 175.861 38.0401 175.403 37.8521C174.945 37.6641 174.453 37.5726 173.958 37.5832C173.459 37.5803 172.963 37.6759 172.501 37.8647C172.039 38.0536 171.619 38.3318 171.266 38.6833C170.912 39.0349 170.632 39.453 170.44 39.9132C170.249 40.3733 170.152 40.8666 170.153 41.3647Z"
          fill="white"
        />
        <path
          d="M62.4261 14.3688C63.069 14.3228 63.7143 14.4197 64.3152 14.6524C64.916 14.8853 65.4578 15.248 65.901 15.7148C66.3442 16.1815 66.6781 16.7407 66.8784 17.3516C67.0788 17.9626 67.1406 18.6103 67.0596 19.2481C67.0596 22.3852 65.3593 24.1885 62.4261 24.1885H58.8691V14.3688H62.4261ZM60.3986 22.7998H62.2552C62.7146 22.8272 63.1745 22.7521 63.6011 22.5799C64.0278 22.4078 64.4107 22.1429 64.7217 21.8045C65.0327 21.4663 65.2642 21.0631 65.3995 20.6243C65.5346 20.1856 65.5698 19.7222 65.5027 19.2681C65.5649 18.8158 65.5261 18.3554 65.3889 17.9198C65.2518 17.4843 65.0196 17.0843 64.7092 16.7488C64.3989 16.4132 64.0179 16.1501 63.5937 15.9786C63.1694 15.807 62.7123 15.7312 62.2552 15.7565H60.3986V22.7998Z"
          fill="white"
        />
        <path
          d="M68.7871 20.4798C68.7404 19.9928 68.7962 19.5015 68.951 19.0372C69.106 18.573 69.3564 18.1462 69.6864 17.7843C70.0164 17.4222 70.4186 17.133 70.8672 16.935C71.3159 16.7371 71.801 16.6348 72.2916 16.6348C72.7823 16.6348 73.2675 16.7371 73.7161 16.935C74.1647 17.133 74.5669 17.4222 74.8969 17.7843C75.2268 18.1462 75.4772 18.573 75.6322 19.0372C75.7871 19.5015 75.8429 19.9928 75.7961 20.4798C75.8438 20.9674 75.7886 21.4593 75.6341 21.9244C75.4797 22.3893 75.2293 22.8169 74.8993 23.1796C74.5692 23.5424 74.1667 23.8322 73.7177 24.0306C73.2686 24.2289 72.7828 24.3314 72.2916 24.3314C71.8004 24.3314 71.3147 24.2289 70.8655 24.0306C70.4165 23.8322 70.014 23.5424 69.6839 23.1796C69.354 22.8169 69.1035 22.3893 68.9491 21.9244C68.7947 21.4593 68.7394 20.9674 68.7871 20.4798ZM74.2877 20.4798C74.2877 18.8735 73.564 17.9341 72.294 17.9341C71.0193 17.9341 70.302 18.8735 70.302 20.4798C70.302 22.0991 71.0193 23.0312 72.294 23.0312C73.5641 23.0311 74.2877 22.0926 74.2877 20.4798Z"
          fill="white"
        />
        <path
          d="M85.1132 24.1883H83.5917L82.0558 18.7305H81.9398L80.4104 24.1883H78.9035L76.855 16.7778H78.3426L79.6738 22.4325H79.7834L81.3113 16.7778H82.7182L84.246 22.4325H84.3622L85.6868 16.7778H87.1535L85.1132 24.1883Z"
          fill="white"
        />
        <path
          d="M88.8765 16.7777H90.2882V17.955H90.3978C90.5838 17.5322 90.8973 17.1778 91.2948 16.9411C91.6924 16.7044 92.154 16.5974 92.6155 16.6347C92.977 16.6077 93.3402 16.662 93.6778 16.7938C94.0155 16.9256 94.319 17.1316 94.5661 17.3963C94.8131 17.6609 94.9973 17.9776 95.1048 18.3229C95.2125 18.6683 95.2407 19.0333 95.1876 19.391V24.1883H93.721V19.7583C93.721 18.5673 93.2021 17.9751 92.1174 17.9751C91.8719 17.9638 91.6269 18.0053 91.3991 18.0972C91.1713 18.1891 90.966 18.3291 90.7974 18.5074C90.6289 18.6858 90.5011 18.8984 90.4226 19.1307C90.3442 19.3629 90.317 19.6093 90.3431 19.853V24.1883H88.8765V16.7777Z"
          fill="white"
        />
        <path d="M97.5244 13.885H98.991V24.1883H97.5244V13.885Z" fill="white" />
        <path
          d="M101.03 20.48C100.983 19.9928 101.039 19.5015 101.194 19.0372C101.349 18.5731 101.599 18.1462 101.929 17.7842C102.259 17.4222 102.661 17.1329 103.11 16.935C103.559 16.7371 104.044 16.6348 104.535 16.6348C105.025 16.6348 105.51 16.7371 105.959 16.935C106.408 17.1329 106.81 17.4222 107.14 17.7842C107.47 18.1462 107.721 18.5731 107.876 19.0372C108.03 19.5015 108.086 19.9928 108.04 20.48C108.087 20.9675 108.032 21.4595 107.877 21.9245C107.723 22.3894 107.473 22.817 107.142 23.1798C106.812 23.5424 106.41 23.8323 105.961 24.0306C105.512 24.2291 105.026 24.3315 104.535 24.3315C104.043 24.3315 103.558 24.2291 103.109 24.0306C102.659 23.8323 102.257 23.5424 101.927 23.1798C101.597 22.817 101.346 22.3894 101.192 21.9245C101.037 21.4595 100.982 20.9675 101.03 20.48ZM106.53 20.48C106.53 18.8736 105.807 17.9342 104.537 17.9342C103.262 17.9342 102.545 18.8736 102.545 20.48C102.545 22.0992 103.262 23.0312 104.537 23.0312C105.807 23.0312 106.53 22.0926 106.53 20.48Z"
          fill="white"
        />
        <path
          d="M109.583 22.0926C109.583 20.7587 110.579 19.9897 112.347 19.8804L114.36 19.7646V19.125C114.36 18.3423 113.842 17.9003 112.839 17.9003C112.02 17.9003 111.453 18.2001 111.29 18.724H109.87C110.02 17.4512 111.221 16.6348 112.907 16.6348C114.77 16.6348 115.821 17.5597 115.821 19.125V24.1883H114.409V23.1469H114.293C114.057 23.5204 113.726 23.825 113.334 24.0294C112.942 24.2339 112.503 24.3308 112.061 24.3104C111.749 24.3427 111.433 24.3096 111.135 24.213C110.837 24.1163 110.562 23.9585 110.329 23.7496C110.095 23.5406 109.908 23.2852 109.78 22.9998C109.652 22.7144 109.585 22.4053 109.583 22.0926ZM114.36 21.4593V20.8398L112.546 20.9556C111.522 21.0239 111.058 21.371 111.058 22.0242C111.058 22.6913 111.638 23.0794 112.436 23.0794C112.67 23.103 112.906 23.0794 113.131 23.0101C113.355 22.9409 113.563 22.8273 113.743 22.6762C113.922 22.5251 114.069 22.3394 114.176 22.1304C114.282 21.9214 114.345 21.6931 114.36 21.4593Z"
          fill="white"
        />
        <path
          d="M117.749 20.4799C117.749 18.1382 118.956 16.6549 120.833 16.6549C121.298 16.6335 121.759 16.7444 122.162 16.9747C122.566 17.205 122.895 17.5449 123.112 17.955H123.222V13.885H124.688V24.1883H123.283V23.0175H123.167C122.933 23.4248 122.592 23.7607 122.181 23.9885C121.769 24.2164 121.303 24.3278 120.833 24.3105C118.943 24.3105 117.749 22.8271 117.749 20.4799ZM119.263 20.4799C119.263 22.0517 120.006 22.9975 121.249 22.9975C122.485 22.9975 123.249 22.038 123.249 20.4862C123.249 18.9419 122.477 17.9687 121.249 17.9687C120.014 17.9687 119.263 18.9209 119.263 20.4799Z"
          fill="white"
        />
        <path
          d="M130.756 20.4798C130.709 19.9928 130.765 19.5015 130.92 19.0372C131.075 18.573 131.325 18.1462 131.655 17.7843C131.985 17.4222 132.387 17.133 132.836 16.935C133.285 16.7371 133.77 16.6348 134.26 16.6348C134.751 16.6348 135.236 16.7371 135.685 16.935C136.133 17.133 136.536 17.4222 136.866 17.7843C137.196 18.1462 137.446 18.573 137.601 19.0372C137.756 19.5015 137.812 19.9928 137.765 20.4798C137.812 20.9674 137.757 21.4593 137.604 21.9244C137.449 22.3893 137.198 22.8169 136.868 23.1796C136.538 23.5424 136.136 23.8322 135.686 24.0306C135.237 24.2289 134.751 24.3314 134.26 24.3314C133.769 24.3314 133.284 24.2289 132.834 24.0306C132.385 23.8322 131.983 23.5424 131.653 23.1796C131.323 22.8169 131.072 22.3893 130.918 21.9244C130.763 21.4593 130.708 20.9674 130.756 20.4798ZM136.256 20.4798C136.256 18.8735 135.533 17.9341 134.263 17.9341C132.988 17.9341 132.271 18.8735 132.271 20.4798C132.271 22.0991 132.988 23.0312 134.263 23.0312C135.533 23.0311 136.256 22.0926 136.256 20.4798Z"
          fill="white"
        />
        <path
          d="M139.733 16.7777H141.145V17.955H141.254C141.441 17.5322 141.754 17.1778 142.151 16.9411C142.549 16.7044 143.011 16.5974 143.472 16.6347C143.834 16.6077 144.196 16.662 144.535 16.7938C144.872 16.9256 145.175 17.1316 145.422 17.3963C145.669 17.6609 145.854 17.9776 145.961 18.3229C146.069 18.6683 146.097 19.0333 146.045 19.391V24.1883H144.577V19.7583C144.577 18.5673 144.059 17.9751 142.974 17.9751C142.729 17.9638 142.483 18.0053 142.255 18.0972C142.028 18.1891 141.822 18.3291 141.653 18.5074C141.486 18.6858 141.357 18.8984 141.279 19.1307C141.201 19.3629 141.173 19.6093 141.199 19.853V24.1883H139.733V16.7777Z"
          fill="white"
        />
        <path
          d="M154.331 14.9329V16.8117H155.941V18.0435H154.331V21.854C154.331 22.6303 154.652 22.9703 155.383 22.9703C155.569 22.9697 155.756 22.9583 155.941 22.9365V24.1548C155.678 24.2017 155.412 24.2266 155.144 24.2294C153.512 24.2294 152.864 23.6573 152.864 22.2286V18.0435H151.684V16.8116H152.864V14.9329H154.331Z"
          fill="white"
        />
        <path
          d="M157.945 13.885H159.399V17.9687H159.515C159.71 17.5419 160.032 17.1855 160.437 16.948C160.843 16.7104 161.312 16.6033 161.781 16.6412C162.14 16.6217 162.499 16.6816 162.834 16.8166C163.167 16.9517 163.468 17.1585 163.712 17.4223C163.956 17.6862 164.14 18.0005 164.248 18.3431C164.356 18.6857 164.388 19.0479 164.34 19.4039V24.1884H162.871V19.7647C162.871 18.5811 162.318 17.9816 161.283 17.9816C161.031 17.9609 160.777 17.9955 160.54 18.0828C160.302 18.17 160.087 18.308 159.909 18.487C159.73 18.6659 159.593 18.8815 159.508 19.1188C159.422 19.356 159.389 19.6091 159.411 19.8603V24.1883H157.945V13.885Z"
          fill="white"
        />
        <path
          d="M172.891 22.1874C172.692 22.8646 172.26 23.4505 171.671 23.8425C171.083 24.2344 170.374 24.4074 169.671 24.3312C169.181 24.3441 168.695 24.2507 168.246 24.0572C167.796 23.8639 167.394 23.5752 167.066 23.2112C166.74 22.8474 166.497 22.4169 166.353 21.9498C166.21 21.4826 166.171 20.9902 166.238 20.5063C166.173 20.021 166.213 19.5274 166.356 19.0589C166.498 18.5904 166.741 18.158 167.065 17.7909C167.39 17.4238 167.79 17.1306 168.239 16.931C168.687 16.7316 169.174 16.6305 169.665 16.6346C171.732 16.6346 172.98 18.0434 172.98 20.3704V20.8809H167.733V20.9627C167.709 21.2347 167.744 21.5084 167.833 21.7664C167.922 22.0244 168.065 22.2609 168.251 22.4607C168.438 22.6606 168.664 22.8195 168.915 22.9272C169.166 23.0347 169.437 23.0887 169.712 23.0857C170.063 23.1277 170.418 23.0647 170.732 22.9048C171.047 22.7448 171.307 22.495 171.479 22.1873L172.891 22.1874ZM167.733 19.7992H171.486C171.504 19.5504 171.47 19.3007 171.386 19.0658C171.303 18.8308 171.17 18.6162 170.997 18.4353C170.825 18.2546 170.617 18.1117 170.386 18.0159C170.155 17.9202 169.906 17.8737 169.657 17.8795C169.403 17.8762 169.151 17.9238 168.916 18.0191C168.682 18.1144 168.468 18.2558 168.288 18.4346C168.108 18.6136 167.967 18.8265 167.873 19.0608C167.777 19.2952 167.729 19.5462 167.733 19.7992Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_890_9231">
        <rect width={197.486} height={65.8286} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AppleAppButtonIcon;

import { useTranslation } from 'next-i18next';

import { RegionSelector } from '@/components/ui';

import { getRegions } from '../../utils/regions/regionsConfig';

const Regions = () => {
  const { t } = useTranslation();

  const regions = getRegions();
  const updatedRegions = regions.map((region) => ({
    countryName: t(region.translationKey),
    ...region,
  }));

  return (
    <>
      <RegionSelector title={t('regionPicker.title')} regions={updatedRegions} />
    </>
  );
};

export default Regions;
